import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import { Spin } from 'antd';
import { UserListActions } from '../../user-management/user-list/state/userListActions';
import { NotificationSubscriptionsActionTypes } from '../interfaces/Notification.types';
import { StyledNotificationsSubscriptionsDrawer } from './NotificationsSubscriptionsDrawer.styles';
import { NotificationsOperator } from './NotificationsOperator/NotificationsOperator';
import { notificationsSubscriptionsActions } from './state/notificationsSubscriptionsSlice';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import {
  selectIsGetNotificationSubscriptionModalLoading,
  selectIsNotificationSubscriptionModalVisible,
} from 'app/modules/notification/modals/state/notificationModalsSelectors';
import { NotificationModalActions } from 'app/modules/notification/modals/state/notificationModalsActions';
import { UserListSelectors } from 'app/modules/user-management/user-list/state/UserListSelectors';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { selectUserId, selectUserInfo } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { InfiniteScrollConstants } from 'config/constants';

export const NotificationsSubscriptionsDrawer = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isVisible = useSelector(selectIsNotificationSubscriptionModalVisible);
  const isLoading = useSelector(selectIsGetNotificationSubscriptionModalLoading);
  const operatorsList = useSelector(UserListSelectors.selectOperatorData);
  const operatorsListLoading = useSelector(UserListSelectors.selectOperatorIsLoading);
  const userLoginInfo = useSelector(selectUserInfo);
  const userId = useSelector(selectUserId);
  const userLanguage = userLoginInfo.preferredLanguage ? userLoginInfo.preferredLanguage.slice(0, 2) : 'en';

  const handleClose = useCallback(() => {
    dispatch(NotificationModalActions.hideNotificationSubscriptionModal());
    dispatch(NotificationModalActions.getNotificationSubscriptionResetState());
  }, [dispatch]);

  useEffect(() => () => handleClose(), [handleClose]);
  useEffect(() => {
    dispatch(
      UserListActions.getUserListOperatorRequest({
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
          paginationToken: '',
        },
      })
    );
  }, [dispatch]);

  const handleAddOperator = useCallback(() => {
    dispatch(notificationsSubscriptionsActions.openInsertOperatorDrawer());
    dispatch(
      notificationsSubscriptionsActions.changetypeInsertOperatorDrawer(
        NotificationSubscriptionsActionTypes.CREATE_OPERATOR
      )
    );
  }, [dispatch]);

  const mySelfName = useMemo(() => userLoginInfo.displayName || '', [userLoginInfo]);

  const operators = useMemo(() => operatorsList?.filter(operator => operator.email) || [], [operatorsList]);
  return (
    <StyledNotificationsSubscriptionsDrawer
      className="notifications-subscriptions-panel-drawer"
      size="small"
      open={isVisible}
      onClose={handleClose}
      loading={isLoading}
      destroyOnClose
    >
      <div className="notifications-subscriptions-drawer__header">
        <h1 className="notifications-subscriptions-drawer__title">
          {t('notificationList.notificationsSubscriptionsPanel.title')}
        </h1>
      </div>
      <div className="notifications-subscriptions-drawer__body">
        <div className="notifications-subscriptions-drawer__info">
          <span className="notifications-subscriptions-drawer__sub-title">
            {t('notificationList.notificationsSubscriptionsPanel.MySelf')}
          </span>
          <NotificationsOperator
            key={userId}
            name={mySelfName}
            isOperator={false}
            userId={userId}
            language={userLanguage}
          />
        </div>
        <div className="notifications-subscriptions-drawer__info">
          <span className="notifications-subscriptions-drawer__sub-title">
            {t('notificationList.notificationsSubscriptionsPanel.otherOperator')}
          </span>
          <span className="notifications-subscriptions-drawer__description">
            {t('notificationList.notificationsSubscriptionsPanel.description')}
          </span>
          <div className="notifications-subscriptions-drawer__operators">
            {operatorsListLoading ? (
              <Spin />
            ) : (
              operators &&
              operators.map(operator => (
                <NotificationsOperator
                  key={operator.id}
                  name={operator.name || ''}
                  isOperator={true}
                  userId={operator.id || ''}
                />
              ))
            )}
          </div>

          <SecondaryButton
            icon={<SvgIcon name="plus"></SvgIcon>}
            className="notifications-subscriptions-drawer__add-btn"
            onClick={handleAddOperator}
          >
            {t('notificationList.notificationsSubscriptionsPanel.addOperator')}
          </SecondaryButton>
        </div>
      </div>

      <div className="notifications-subscriptions-drawer__footer">
        <SecondaryButton onClick={handleClose} size="m" className="notifications-subscriptions-drawer__close-button">
          {t('common.close')}
        </SecondaryButton>
      </div>
    </StyledNotificationsSubscriptionsDrawer>
  );
};
