import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest } from 'typed-redux-saga';
import { IDependencies } from '../../../../cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import {
  IGetNotificationSubscriptionErrorAction,
  IGetNotificationSubscriptionSuccessAction,
  NotificationModalActions,
} from './notificationModalsActions';
import { Optional } from 'lib/types/Optional';
import { ResponseNotificationUser } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export function* getNotificationSubscriptionSaga(): Generator<
  | GetContextEffect
  | CallEffect<Optional<ResponseNotificationUser>>
  | PutEffect<IGetNotificationSubscriptionSuccessAction>
  | PutEffect<IGetNotificationSubscriptionErrorAction>,
  void,
  IDependencies
> {
  const { notificationService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(notificationService.getUserNotification);

    yield* put(NotificationModalActions.getNotificationSubscriptionSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      NotificationModalActions.getNotificationSubscriptionError({
        error,
      })
    );
  }
}

export function* notificationModalsSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(NotificationModalActions.GET_NOTIFICATION_SUBSCRIPTION_REQUEST, getNotificationSubscriptionSaga);
}
