import { useTranslation } from 'react-i18next';
import { Col, Form, Row, Spin } from 'antd';
import { countries } from '../countryData/countryData';
import { Card } from 'lib/components/Card/Card';
import { Input } from 'lib/components/Input/Input';
import { Checkbox } from 'lib/components/Checkbox/Checkbox';
import { supportedLanguages } from 'app/cross-cutting-concerns/translations/Translations';
import { Select } from 'lib/components/Select/Select';
import { NotificationSubscriptionsActionTypes } from 'app/modules/notification/interfaces/Notification.types';

const languageOptions = Object.entries(supportedLanguages).map(([key, values]) => ({
  value: key === 'en-US' ? 'en' : key,
  content: values.name,
}));

interface ContactInfoFormProps {
  isFormLoading: boolean;
  countryCode?: string;

  typeDrawer?: NotificationSubscriptionsActionTypes;
}

export const ContactInfoForm = ({ isFormLoading, countryCode, typeDrawer }: ContactInfoFormProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Card
      className="operator-details-drawer__card operator-details-drawer__information"
      title={t('notificationList.insertPanel.contactInfo.title')}
    >
      <Spin spinning={isFormLoading}>
        <Form.Item
          name="name"
          label={t('notificationList.insertPanel.contactInfo.name')}
          className="operator-details-drawer__input-group"
          required
          rules={[{ required: true, message: t('userList.operatorForm.form.errors.nameRequired') }]}
        >
          <Input
            className="operator-details-drawer__input"
            disabled={typeDrawer === NotificationSubscriptionsActionTypes.EDIT_NOTIFICATION_SUBSCRIPTION}
          />
        </Form.Item>

        <Form.Item
          name="language"
          label={t('notificationList.insertPanel.contactInfo.language')}
          className="operator-details-drawer__input-group operator-details-drawer__select"
          required
          rules={[{ required: true, message: t('userList.operatorForm.form.errors.languageRequired') }]}
        >
          <Select
            dropdownVisibleState
            className="operator-details-drawer__select"
            placeholder={t('userList.operatorForm.form.placeholderLanguage')}
            options={languageOptions.map(({ value, content }) => ({
              label: t(content),
              value,
            }))}
          />
        </Form.Item>

        <Form.Item
          name="email"
          label={t('notificationList.insertPanel.contactInfo.email')}
          className="operator-details-drawer__input-group"
          required
          rules={[
            { required: true, message: t('userList.operatorForm.form.errors.emailRequired') },
            {
              type: 'email',
              message: t('userList.operatorForm.form.errors.emailInvalid'),
            },
          ]}
        >
          <Input className="operator-details-drawer__input" />
        </Form.Item>

        <Form.Item
          label={t('notificationList.insertPanel.contactInfo.phoneNumber')}
          className="operator-details-drawer__input-group"
        >
          <Row>
            <Form.Item
              name="countryCode"
              noStyle
              rules={[
                {
                  required: true,
                  message: t('userList.operatorForm.form.errors.countryCodeRequired'),
                },
              ]}
            >
              <Select
                dropdownVisibleState
                className="operator-details-drawer__input__select-phone-code"
                options={countries.map(country => ({
                  label: (
                    <div className="operator-form__select-label">
                      {country.flag && <country.flag style={{ height: '22px', width: '24px', marginRight: '8px' }} />}

                      <p>
                        {country.countryCode} ({country.phoneCode})
                      </p>
                    </div>
                  ),
                  value: country.countryCode,
                }))}
                defaultValue={countryCode}
                filterOption={(input, option): boolean => {
                  if (!option || option.value == null || option.label == null) return false;
                  const label = option.label as React.ReactElement;
                  const inputValue = input.toLowerCase();
                  const labelTextArray = label.props.children[1].props.children || '';
                  return labelTextArray.toString().toLowerCase().includes(inputValue);
                }}
              />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              noStyle
              rules={[
                {
                  required: true,
                  message: t('userList.operatorForm.form.errors.phoneNumberRequired'),
                },
                {
                  pattern: /^\d{9,12}$/,
                  message: t('userList.operatorForm.form.phoneNumberInvalid'),
                },
              ]}
            >
              <Input className="operator-details-drawer__input-phone-code" />
            </Form.Item>
          </Row>
        </Form.Item>
        <Row>
          <span className="operator-details-drawer__selected-notifications--sub-title">
            {t('notificationList.insertPanel.notificationSelect.title')}
          </span>
        </Row>

        <Row className="operator-details-drawer__selected-notifications--checkbox-row">
          <Col span={5}>
            <Form.Item name="emailSubscription" className="operator-form__checkbox" required valuePropName="checked">
              <Checkbox>{t('notificationList.insertPanel.notificationSelect.byEmail')}</Checkbox>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="smsSubscription" className="operator-form__checkbox" required valuePropName="checked">
              <Checkbox>{t('notificationList.insertPanel.notificationSelect.bySMS')}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};
