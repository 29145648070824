import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Flex, Form, Row } from 'antd';
import { NotificationsSubscriptionSelector } from '../state/notificationsSubscriptionsSelectors';
import { notificationsSubscriptionsActions } from '../state/notificationsSubscriptionsSlice';
import { NotificationSubscriptionsActionTypes } from '../../interfaces/Notification.types';
import { UpsertNotificationFormValues } from '../../interfaces/Operator.types';
import { StyledUpsertNotificationsDrawer } from './UpsertNotificationsDrawer.styles';
import { countries } from './countryData/countryData';
import { ContactInfoForm } from './ContactInfoForm/ContactInfoForm';
import { AssignedDevices } from './AssignedDevices/AssignedDevices';
import { NotificationsSelect } from './NotificationsSelect/NotificationsSelect';
import { PopConfirm } from 'lib/components/PopConfirm/PopConfirm';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { PermissionGuard } from 'app/cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';
import { Permission } from 'config/permissions';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { Optional } from 'lib/types/Optional';
import { useAnalyticsSetForm } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import { AnalyticsForm } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { OpenSearch } from 'config/constants';

export const UpsertNotificationsDrawer = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formInstance] = Form.useForm();

  const timezone = DateTime.getBrowserTimeZone();
  const [assignedDevices, setAssignedDevices] = useState<Optional<string>[]>([]);

  const [fields, setFields] = useState<Optional<UpsertNotificationFormValues>>(undefined);

  const handleValuesChange = (): void => {
    setFields(formInstance.getFieldsValue());
  };

  const isVisible = useSelector(NotificationsSubscriptionSelector.selectIsOpenUpsertNotificationsDrawer);
  const typeDrawer = useSelector(NotificationsSubscriptionSelector.selectNotificationSubscriptionsType);

  const isDeleteOperatorLoading = useSelector(NotificationsSubscriptionSelector.selectIsDeleteOperatorLoading);

  const handleClose = useCallback(() => {
    dispatch(notificationsSubscriptionsActions.closeInsertOperatorDrawer());
    dispatch(notificationsSubscriptionsActions.resetNotificationSubscriptions());
  }, [dispatch]);

  const machineList = useSelector(NotificationsSubscriptionSelector.selectMachineListData);
  const isMachineListLoading = useSelector(NotificationsSubscriptionSelector.selectIsMachineListLoading);

  const isSubmittingLoading = useSelector(NotificationsSubscriptionSelector.selectIsFormSubmitLoading);
  const isFormLoading = useSelector(NotificationsSubscriptionSelector.selectIsFormLoading);

  const initialData = useSelector(NotificationsSubscriptionSelector.selectFormData);

  const handleDeleteOperator = useCallback(() => {
    dispatch(notificationsSubscriptionsActions.deleteOperatorRequest({ id: initialData.id || '' }));
  }, [dispatch, initialData.id]);

  useEffect(() => {
    if (isVisible) {
      dispatch(
        notificationsSubscriptionsActions.listMachineRequest({
          paginationOptions: {
            limit: OpenSearch.MAX_RESULT_WINDOW,
          },
        })
      );
    }
  }, [dispatch, isVisible]);

  useEffect(() => {
    if (isVisible && !isFormLoading && !isMachineListLoading) {
      if (initialData.phoneNumber && initialData.countryCode) {
        const phoneCode = countries.find(country => country.phoneCode === initialData.countryCode)?.countryCode || '';
        const phoneNumber = initialData.phoneNumber?.replace(phoneCode || '', '') || '';
        formInstance.setFieldsValue({ ...initialData, phoneNumber });
      } else {
        formInstance.setFieldsValue(initialData);
      }

      if (initialData.assignedMachines.length !== 0) {
        const validMachineIds = initialData.assignedMachines
          .map(machine => machine.id)
          .filter((id): id is string => id !== undefined && id !== null);
        setAssignedDevices(validMachineIds);
      }
    }
  }, [isVisible, initialData, formInstance, isFormLoading, isMachineListLoading]);

  const onFinish = useCallback(
    (values: UpsertNotificationFormValues) => {
      if (assignedDevices.length === 0) {
        return;
      }

      const assignedMachines =
        machineList
          ?.filter(machine => assignedDevices.includes(machine.id))
          .map(machine => ({
            id: machine.id as string,
            name: machine.name || '',
          })) || [];

      const phoneCode = countries.find(country => country.countryCode === values.countryCode)?.phoneCode || '';
      let phoneInput = values.phoneNumber || '';
      if (phoneInput.startsWith('0')) {
        phoneInput = phoneInput.substring(1);
      }

      const phoneNumber = phoneCode + phoneInput;

      dispatch(
        notificationsSubscriptionsActions.saveFormData({
          ...initialData,
          ...values,
          assignedMachines,
          phoneNumber,
          timezone,
        })
      );

      if (typeDrawer === NotificationSubscriptionsActionTypes.EDIT_NOTIFICATION_SUBSCRIPTION) {
        dispatch(notificationsSubscriptionsActions.updateNotificationUserRequest());
      } else {
        dispatch(notificationsSubscriptionsActions.upsertOperatorRequest());
      }
    },
    [dispatch, typeDrawer, assignedDevices, machineList, initialData, timezone]
  );

  useAnalyticsSetForm({
    name: AnalyticsForm.INSERT_NOTIFICATION_DETAILS,
    fields: fields as Record<string, any>,
    isVisible,
  });

  const handleSave = useCallback(() => {
    setFields({ ...formInstance.getFieldsValue() });

    formInstance.submit();
  }, [formInstance]);

  return (
    <StyledUpsertNotificationsDrawer
      className="insert-notifications-drawer-panel"
      title={
        <Row className="insert-notifications-drawer-panel--header">
          <TextButton className="insert-notifications-drawer__icon" onClick={handleClose}>
            <SvgIcon className="insert-notifications-drawer-icon" name="arrowBack"></SvgIcon>
          </TextButton>
          <span className="insert-notifications-drawer__name">
            {initialData.name !== '' ? initialData.name : t('notificationList.insertPanel.addOperator')}
          </span>
        </Row>
      }
      size="small"
      open={isVisible}
      onClose={handleClose}
      destroyOnClose
      footer={[
        <Col className="operator-details-drawer__changes-button-col" key="changes-button">
          <PopConfirm
            key="cancel-button"
            title={t('common.popConfirm.title')}
            description={t('common.popConfirm.description')}
            cancelText={t('common.popConfirm.cancelText')}
            okText={t('common.popConfirm.okText')}
          >
            <SecondaryButton size="m" key="cancel-button" className="operator-details-drawer__secondary-button">
              {t('common.discardChanges')}
            </SecondaryButton>
          </PopConfirm>
          <PrimaryButton
            size="m"
            key="submit"
            className="operator-details-drawer__submit-button"
            loading={isSubmittingLoading}
            onClick={handleSave}
          >
            {t('common.saveChanges')}
          </PrimaryButton>
        </Col>,
      ]}
    >
      <Form
        form={formInstance}
        name="insert-notifications-details-drawer"
        layout="vertical"
        autoComplete="off"
        onValuesChange={handleValuesChange}
        onFinish={onFinish}
      >
        <Flex vertical>
          <ContactInfoForm
            countryCode={initialData.countryCode || ''}
            isFormLoading={isFormLoading || isMachineListLoading}
            typeDrawer={typeDrawer || undefined}
          />
          <AssignedDevices
            initAssignedDevices={initialData.assignedMachines}
            isLoading={isFormLoading || isMachineListLoading}
            assignedDevices={assignedDevices}
            handleAssignedDevices={setAssignedDevices}
            machineList={machineList || []}
          />

          <NotificationsSelect isFormLoading={isFormLoading || isMachineListLoading} formInstance={formInstance} />

          {typeDrawer === NotificationSubscriptionsActionTypes.EDIT_OPERATOR && (
            <div className="operator-details-drawer__delete-button-wrapper" key="delete-button">
              <PermissionGuard requiredPermissions={[Permission.Customer.User.DELETE_OPERATOR]} key="delete-button">
                <SecondaryButton
                  size="m"
                  title={t('operatorDetailsPanel.deleteBtn')}
                  key="delete-button"
                  className="operator-details-drawer__secondary-button"
                  onClick={handleDeleteOperator}
                  loading={isDeleteOperatorLoading}
                >
                  {t('operatorDetailsPanel.deleteBtn')}
                </SecondaryButton>
              </PermissionGuard>
            </div>
          )}
        </Flex>
      </Form>
    </StyledUpsertNotificationsDrawer>
  );
};
