import styled from 'styled-components';
import { Drawer } from 'lib/components/Drawer/Drawer';

export const StyledNotificationsSubscriptionsDrawer = styled(Drawer)`
  ${(props): string => props.theme.fontStyles.redesign.base.normal};
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: ${(props): string => props.theme.spacing.lg};
  }

  .notifications-subscriptions-drawer__header {
    flex-grow: 0;
  }

  .notifications-subscriptions-drawer__title {
    ${(props): string => props.theme.fontStyles.redesign.heading4};
    font-weight: ${(props): string => props.theme.fontStyles.heading2};
    font-size: ${(props): string => props.theme.fontSizes.redesign.heading4};
    line-height: ${(props): string => props.theme.lineHeights.redesign.heading4};
    text-transform: uppercase;
    margin-bottom: 0px;
  }

  .notifications-subscriptions-drawer__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: ${(props): string => props.theme.spacing.lg};
    overflow: auto;

    .notifications-subscriptions-drawer__sub-title {
      ${(props): string => props.theme.fontStyles.redesign.lg.strong};
      font-weight: ${(props): string => props.theme.fontStyles.subHeading};
      font-size: ${(props): string => props.theme.fontSizes.redesign.lg};
      line-height: ${(props): string => props.theme.lineHeights.redesign.lg};
    }
  }

  .notifications-subscriptions-drawer__info {
    display: flex;
    flex-direction: column;
    gap: ${(props): string => props.theme.spacing.sm};
  }

  .notifications-subscriptions-drawer__add-btn {
    svg {
      height: 20px;
      width: 20px;
    }
    flex-flow: unset;
    border: 1px solid ${(props): string => props.theme.colors.brightGrey};
    span {
      ${(props): string => props.theme.fontStyles.redesign.lg.strong};
      font-weight: ${(props): string => props.theme.fontStyles.subHeading};
      line-height: ${(props): string => props.theme.lineHeights.redesign.lg};
      font-size: ${(props): string => props.theme.fontSizes.redesign.lg};
    }
    width: fit-content;
    height: unset !important;
    padding: ${(props): string => props.theme.spacing.xs} ${(props): string => props.theme.spacing.base} !important;
    .ant-btn-icon {
      line-height: 1;
    }
  }

  .notifications-operator__arrow-icon {
    svg {
      path {
        stroke-width: 1;
      }
    }
  }

  .notifications-subscriptions-drawer__operators {
    .ant-spin {
      width: 100%;
    }
  }

  .notification-subscription-drawer__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 0;
  }

  .notifications-subscriptions-drawer__close-button {
    &.ant-btn.button-base.button__size-m {
      ${(props): string => props.theme.fontStyles.redesign.base.delete};
    }
  }
`;
