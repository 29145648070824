import { Action } from 'redux';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { Optional } from 'lib/types/Optional';
import {
  ResponseNotificationUpdateNotifyStatus,
  ResponseNotificationUser,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export type IShowNotificationSubscriptionModalAction = Action<
  typeof NotificationModalActions.SHOW_NOTIFICATION_SUBSCRIPTION_MODAL
>;
export type IHideNotificationSubscriptionModalAction = Action<
  typeof NotificationModalActions.HIDE_NOTIFICATION_SUBSCRIPTION_MODAL
>;
export type INotificationSubscriptionModalIsLoadingAction = Action<
  typeof NotificationModalActions.NOTIFICATION_SUBSCRIPTION_MODAL_IS_LOADING
>;
export type INotificationSubscriptionModalIsNotLoadingAction = Action<
  typeof NotificationModalActions.NOTIFICATION_SUBSCRIPTION_MODAL_IS_NOT_LOADING
>;

export type IGetNotificationSubscriptionRequestAction = Action<
  typeof NotificationModalActions.GET_NOTIFICATION_SUBSCRIPTION_REQUEST
>;

export type IGetNotificationSubscriptionSuccessOptions = Optional<ResponseNotificationUser>;
export type IGetNotificationSubscriptionSuccessAction = Action<
  typeof NotificationModalActions.GET_NOTIFICATION_SUBSCRIPTION_SUCCESS
> & {
  payload: Optional<ResponseNotificationUser>;
};

export type IGetNotificationSubscriptionErrorAction = IErrorAction<
  typeof NotificationModalActions.GET_NOTIFICATION_SUBSCRIPTION_ERROR
>;

export type IGetNotificationSubscriptionResetStateAction = Action<
  typeof NotificationModalActions.GET_NOTIFICATION_SUBSCRIPTION_RESET_STATE
>;

export type ISaveNotificationSubscriptionSuccessOptions = Optional<ResponseNotificationUpdateNotifyStatus>;
export type ISaveNotificationSubscriptionSuccessAction = Action<
  typeof NotificationModalActions.SAVE_NOTIFICATION_SUBSCRIPTION_SUCCESS
> & {
  payload: Optional<ResponseNotificationUpdateNotifyStatus>;
};
export type ISaveNotificationSubscriptionErrorAction = IErrorAction<
  typeof NotificationModalActions.SAVE_NOTIFICATION_SUBSCRIPTION_ERROR
>;

export class NotificationModalActions {
  public static SHOW_NOTIFICATION_SUBSCRIPTION_MODAL = 'SUBSCRIPTION_MODAL::SHOW_NOTIFICATION_SUBSCRIPTION_MODAL';
  public static HIDE_NOTIFICATION_SUBSCRIPTION_MODAL = 'SUBSCRIPTION_MODAL::HIDE_NOTIFICATION_SUBSCRIPTION_MODAL';
  public static NOTIFICATION_SUBSCRIPTION_MODAL_IS_LOADING =
    'SUBSCRIPTION_MODAL::NOTIFICATION_SUBSCRIPTION_MODAL_IS_LOADING';

  public static NOTIFICATION_SUBSCRIPTION_MODAL_IS_NOT_LOADING =
    'SUBSCRIPTION_MODAL::NOTIFICATION_SUBSCRIPTION_MODAL_IS_NOT_LOADING';

  public static GET_NOTIFICATION_SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_MODAL::GET_NOTIFICATION_SUBSCRIPTION_REQUEST';
  public static GET_NOTIFICATION_SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_MODAL::GET_NOTIFICATION_SUBSCRIPTION_SUCCESS';
  public static GET_NOTIFICATION_SUBSCRIPTION_ERROR = 'SUBSCRIPTION_MODAL::GET_NOTIFICATION_SUBSCRIPTION_ERROR';
  public static GET_NOTIFICATION_SUBSCRIPTION_RESET_STATE =
    'SUBSCRIPTION_MODAL::GET_NOTIFICATION_SUBSCRIPTION_RESET_STATE';

  public static SAVE_NOTIFICATION_SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_MODAL::SAVE_NOTIFICATION_SUBSCRIPTION_REQUEST';
  public static SAVE_NOTIFICATION_SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_MODAL::SAVE_NOTIFICATION_SUBSCRIPTION_SUCCESS';
  public static SAVE_NOTIFICATION_SUBSCRIPTION_ERROR = 'SUBSCRIPTION_MODAL::SAVE_NOTIFICATION_SUBSCRIPTION_ERROR';

  public static showNotificationSubscriptionModal(): IShowNotificationSubscriptionModalAction {
    return {
      type: NotificationModalActions.SHOW_NOTIFICATION_SUBSCRIPTION_MODAL,
    };
  }

  public static hideNotificationSubscriptionModal(): IHideNotificationSubscriptionModalAction {
    return {
      type: NotificationModalActions.HIDE_NOTIFICATION_SUBSCRIPTION_MODAL,
    };
  }

  public static NotificationSubscriptionModalIsLoading(): INotificationSubscriptionModalIsLoadingAction {
    return {
      type: NotificationModalActions.NOTIFICATION_SUBSCRIPTION_MODAL_IS_LOADING,
    };
  }

  public static NotificationSubscriptionModalIsNotLoading(): INotificationSubscriptionModalIsNotLoadingAction {
    return {
      type: NotificationModalActions.NOTIFICATION_SUBSCRIPTION_MODAL_IS_NOT_LOADING,
    };
  }

  public static getNotificationSubscriptionRequest(): IGetNotificationSubscriptionRequestAction {
    return {
      type: NotificationModalActions.GET_NOTIFICATION_SUBSCRIPTION_REQUEST,
    };
  }

  public static getNotificationSubscriptionSuccess(
    data: IGetNotificationSubscriptionSuccessOptions
  ): IGetNotificationSubscriptionSuccessAction {
    return {
      type: NotificationModalActions.GET_NOTIFICATION_SUBSCRIPTION_SUCCESS,
      payload: data,
    };
  }

  public static getNotificationSubscriptionError({
    error,
  }: IActionCreatorErrorOptions): IGetNotificationSubscriptionErrorAction {
    return {
      type: NotificationModalActions.GET_NOTIFICATION_SUBSCRIPTION_ERROR,
      error,
    };
  }

  public static getNotificationSubscriptionResetState(): IGetNotificationSubscriptionResetStateAction {
    return {
      type: NotificationModalActions.GET_NOTIFICATION_SUBSCRIPTION_RESET_STATE,
    };
  }

  public static saveNotificationSubscriptionSuccess(
    data: ISaveNotificationSubscriptionSuccessOptions
  ): ISaveNotificationSubscriptionSuccessAction {
    return {
      type: NotificationModalActions.SAVE_NOTIFICATION_SUBSCRIPTION_SUCCESS,
      payload: data,
    };
  }

  public static saveNotificationSubscriptionError({
    error,
  }: IActionCreatorErrorOptions): ISaveNotificationSubscriptionErrorAction {
    return {
      type: NotificationModalActions.SAVE_NOTIFICATION_SUBSCRIPTION_ERROR,
      error,
    };
  }
}
