import { Machine } from '../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { NotificationSubscriptionsActionTypes } from '../../interfaces/Notification.types';
import { UpsertNotificationFormValues } from '../../interfaces/Operator.types';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export class NotificationsSubscriptionSelector {
  public static selectIsOpenUpsertNotificationsDrawer = (state: IState): boolean =>
    state.modules.notification['notification-subscriptions'].insertOperatorDrawer.isOpen;

  public static selectMachineListData = (state: IState): Optional<Machine[]> =>
    state.modules.notification['notification-subscriptions'].insertOperatorDrawer.machines.data;

  public static selectIsMachineListLoading = (state: IState): boolean =>
    state.modules.notification['notification-subscriptions'].insertOperatorDrawer.machines.isLoading;

  public static selectFormData = (state: IState): UpsertNotificationFormValues =>
    state.modules.notification['notification-subscriptions'].insertOperatorDrawer.formData;

  public static selectIsFormLoading = (state: IState): boolean =>
    state.modules.notification['notification-subscriptions'].insertOperatorDrawer.formData.isFormLoading;

  public static selectIsFormSubmitLoading = (state: IState): boolean =>
    state.modules.notification['notification-subscriptions'].insertOperatorDrawer.isSubmitFormLoading;

  public static selectIsDeleteOperatorLoading = (state: IState): boolean =>
    state.modules.notification['notification-subscriptions'].insertOperatorDrawer.isDeleteOperatorLoading;

  public static selectNotificationSubscriptionsType = (state: IState): Optional<NotificationSubscriptionsActionTypes> =>
    state.modules.notification['notification-subscriptions'].insertOperatorDrawer.type;
}
