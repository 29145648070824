import { CallEffect, ForkEffect, GetContextEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { call, getContext, put, select, takeLatest } from 'typed-redux-saga';
import { MachineList } from '../../../machine-inventory/interfaces/Machine.types';
import {
  GetOperatorResponse,
  NotificationDeleteOperatorResponse,
  OperatorResponse,
} from '../../interfaces/Operator.types';
import { NotificationServiceUserLocationStatusOptions } from '../../NotificationService';
import { NotificationSubscriptionsActionTypes } from '../../interfaces/Notification.types';
import {
  INotificationSubscriptionModalIsLoadingAction,
  NotificationModalActions,
} from '../../modals/state/notificationModalsActions';
import * as NotificationSubscriptionsTypes from './notificationSubscriptionsActions.types';
import { notificationsSubscriptionsActions } from './notificationsSubscriptionsSlice';
import { NotificationsSubscriptionSelector } from './notificationsSubscriptionsSelectors';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import {
  MutationNotificationUpsertOperatorArgs,
  ResponseNotificationUpdateNotifyStatus,
  ResponseNotificationUser,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

export function* getMachineListSaga(
  action: NotificationSubscriptionsTypes.ListMachineRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<MachineList>
  | PutEffect<NotificationSubscriptionsTypes.ListMachineSuccessAction>
  | PutEffect<NotificationSubscriptionsTypes.ListMachineErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.list, action.payload);
    yield* put(notificationsSubscriptionsActions.listMachineSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      notificationsSubscriptionsActions.listMachineError({
        error,
      })
    );
  }
}

export function* getNotificaitonUserSaga(): Generator<
  | GetContextEffect
  | CallEffect<Optional<ResponseNotificationUser>>
  | PutEffect<NotificationSubscriptionsTypes.GetNotificationDetailSuccessAction>
  | PutEffect<NotificationSubscriptionsTypes.GetNotificationDetailErrorAction>,
  void,
  IDependencies
> {
  const { notificationService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(notificationService.getUserNotification);

    yield* put(notificationsSubscriptionsActions.getNotificationUserSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      notificationsSubscriptionsActions.getNotificationUserError({
        error,
      })
    );
  }
}

export function* getNotificationOperatorSaga(
  action: NotificationSubscriptionsTypes.GetOperatorDetailRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<GetOperatorResponse> | void>
  | PutEffect<NotificationSubscriptionsTypes.GetOperatorDetailSuccessAction>
  | PutEffect<NotificationSubscriptionsTypes.GetOperatorDetailErrorAction>,
  void,
  IDependencies
> {
  const { operatorService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(operatorService.get, action.payload);

    yield* put(notificationsSubscriptionsActions.getOperatorDetailSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      notificationsSubscriptionsActions.getOperatorDetailError({
        error,
      })
    );
  }
}

export function* updateNotificationUserSaga(): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<Optional<ResponseNotificationUpdateNotifyStatus> | void>
  | PutEffect<NotificationSubscriptionsTypes.UpdateNotificationUserSuccessAction>
  | PutEffect<NotificationSubscriptionsTypes.UpdateNotificationUserErrorAction>
  | PutEffect<INotificationSubscriptionModalIsLoadingAction>,
  void,
  IDependencies
> {
  const { notificationService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  const type = yield* select(NotificationsSubscriptionSelector.selectNotificationSubscriptionsType);

  const input = yield* select(NotificationsSubscriptionSelector.selectFormData);

  try {
    const response = yield* call(notificationService.updateNotifyStatus, {
      assignedMachines: input.assignedMachines,
      countryCode: input.countryCode,
      emailSubscription: input.emailSubscription,
      email: input.email,
      lang: input.language,
      notifyAutonomousTaskStopped: input.notifyAutonomousTaskStopped || false,
      notifyCleaningTaskFinished: input.notifyCleaningTaskFinished || false,
      notifyCleaningTaskInterrupted: input.notifyCleaningTaskInterrupted || false,
      notifyEmergencyStop: input.notifyEmergencyStop || false,
      notifyErrorOccurred: input.notifyErrorOccurred || false,
      notifyInformation: input.notifyInformation || false,
      notifyLocationStatus: input.notifyLocationStatus || false,
      notifyNoWorkStart: input.notifyNoWorkStart || false,
      notifyPCM: input.notifyPCM || false,
      notifyOther: input.notifyOther || false,
      notifyReminder: input.notifyReminder || false,
      notifyResources: input.notifyResources || false,
      notifyServiceNeeded: input.notifyServiceNeeded || false,
      notifyBatteryDeepDischarge: input.notifyBatteryDeepDischarge || false,
      timezone: input.timezone || '',
      phoneNumber: input.phoneNumber || '',
      smsSubscription: input.smsSubscription || false,
    } as NotificationServiceUserLocationStatusOptions);

    yield* put(notificationsSubscriptionsActions.updateNotificationUserSuccess(response));
    yield* put(notificationsSubscriptionsActions.closeInsertOperatorDrawer());
    yield* put(NotificationModalActions.hideNotificationSubscriptionModal());

    if (type === NotificationSubscriptionsActionTypes.EDIT_NOTIFICATION_SUBSCRIPTION) {
      yield* call(toastService.success, {
        message: t('notificationList.toast.notificationSubscription.success.message'),
        description: t('notificationList.toast.notificationSubscription.success.description'),
      });
      yield* put(notificationsSubscriptionsActions.resetNotificationSubscriptions());
    }
  } catch (error) {
    console.error(error);

    yield* put(
      notificationsSubscriptionsActions.updateNotificationUserError({
        error,
      })
    );
  }
}

export function* updateOperatorSaga(): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<Optional<OperatorResponse> | void>
  | PutEffect<NotificationSubscriptionsTypes.UpsertOperatorSuccessAction>
  | PutEffect<NotificationSubscriptionsTypes.UpsertOperatorErrorAction>
  | PutEffect<INotificationSubscriptionModalIsLoadingAction>,
  void,
  IDependencies
> {
  const { operatorService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  const input = yield* select(NotificationsSubscriptionSelector.selectFormData);

  const type = yield* select(NotificationsSubscriptionSelector.selectNotificationSubscriptionsType);

  try {
    const response = yield* call(operatorService.upsert, {
      input: {
        assignedMachines: input.assignedMachines,
        countryCode: input.countryCode,
        email: input.email,
        emailSubscription: input.emailSubscription || false,
        id: input.id || undefined,
        language: input.language,
        name: input.name,
        phoneNumber: input.phoneNumber,
        smsSubscription: input.smsSubscription || false,
        timezone: input.timezone || '',
        notifications: {
          notifyAutonomousTaskStopped: input.notifyAutonomousTaskStopped || false,
          notifyCleaningTaskFinished: input.notifyCleaningTaskFinished || false,
          notifyCleaningTaskInterrupted: input.notifyCleaningTaskInterrupted || false,
          notifyEmergencyStop: input.notifyEmergencyStop || false,
          notifyErrorOccurred: input.notifyErrorOccurred || false,
          notifyInformation: input.notifyInformation || false,
          notifyLocationStatus: input.notifyLocationStatus || false,
          notifyNoWorkStart: input.notifyNoWorkStart || false,
          notifyOther: input.notifyOther || false,
          notifyPCM: input.notifyPCM || false,
          notifyReminder: input.notifyReminder || false,
          notifyResources: input.notifyResources || false,
          notifyServiceNeeded: input.notifyServiceNeeded || false,
          notifyBatteryDeepDischarge: input.notifyBatteryDeepDischarge || false,
        },
      },
    } as MutationNotificationUpsertOperatorArgs);

    yield* put(notificationsSubscriptionsActions.upsertOperatorSuccess(response));

    yield* put(notificationsSubscriptionsActions.closeInsertOperatorDrawer());
    yield* put(NotificationModalActions.hideNotificationSubscriptionModal());

    if (type === NotificationSubscriptionsActionTypes.CREATE_OPERATOR) {
      yield* call(toastService.success, {
        message: t('notificationList.toast.operator.create.success.message'),
        description: t('notificationList.toast.operator.create.success.description'),
      });
    } else if (type === NotificationSubscriptionsActionTypes.EDIT_OPERATOR) {
      yield* call(toastService.success, {
        message: t('notificationList.toast.operator.update.success.message'),
        description: t('notificationList.toast.operator.update.success.description'),
      });
      yield* put(notificationsSubscriptionsActions.resetNotificationSubscriptions());
    }
  } catch (error) {
    console.error(error);

    yield* put(
      notificationsSubscriptionsActions.upsertOperatorError({
        error,
      })
    );
  }
}

export function* deleteOperatorSaga(
  action: NotificationSubscriptionsTypes.DeleteOperatorRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<NotificationDeleteOperatorResponse> | void>
  | PutEffect<NotificationSubscriptionsTypes.DeleteOperatorSuccessAction>
  | PutEffect<NotificationSubscriptionsTypes.DeleteOperatorErrorAction>
  | PutEffect<INotificationSubscriptionModalIsLoadingAction>,
  void,
  IDependencies
> {
  const { operatorService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(operatorService.delete, action.payload);

    yield* put(notificationsSubscriptionsActions.deleteOperatorSuccess(response));

    yield* put(notificationsSubscriptionsActions.closeInsertOperatorDrawer());
    yield* put(NotificationModalActions.hideNotificationSubscriptionModal());

    yield* call(toastService.success, {
      message: t('notificationList.toast.operator.delete.success.message'),
      description: t('notificationList.toast.operator.delete.success.description'),
    });
  } catch (error) {
    console.error(error);

    yield* put(
      notificationsSubscriptionsActions.deleteOperatorError({
        error,
      })
    );
  }
}

export function* notificationsSubscriptionsSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(notificationsSubscriptionsActions.listMachineRequest, getMachineListSaga);
  yield* takeLatest(notificationsSubscriptionsActions.getNotificationUserRequest, getNotificaitonUserSaga);
  yield* takeLatest(notificationsSubscriptionsActions.getOperatorDetailRequest, getNotificationOperatorSaga);
  yield* takeLatest(notificationsSubscriptionsActions.updateNotificationUserRequest, updateNotificationUserSaga);
  yield* takeLatest(notificationsSubscriptionsActions.upsertOperatorRequest, updateOperatorSaga);
  yield* takeLatest(notificationsSubscriptionsActions.deleteOperatorRequest, deleteOperatorSaga);
}
