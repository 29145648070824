import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { getSelectedMachinesColumns } from './columns/SelectedMachines.columns';
import { Table } from 'lib/components/Table/Table';
import { Card } from 'lib/components/Card/Card';
import { AssignedMachineType, Machine } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

interface AssignedDevicesProps {
  initAssignedDevices: AssignedMachineType[];
  isLoading: boolean;
  assignedDevices: Optional<string>[];
  handleAssignedDevices: (ids: Optional<string>[]) => void;
  machineList: Machine[];
}

export const AssignedDevices = ({
  initAssignedDevices,
  isLoading,
  assignedDevices,
  handleAssignedDevices,
  machineList,
}: AssignedDevicesProps): JSX.Element => {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  const sortedData = useMemo(() => {
    const initSelected = initAssignedDevices.map(machine => machine?.id) || [];
    const topDevices = initSelected.map(id => machineList.find(device => device.id === id)).filter(Boolean);
    const bottomDevices = machineList.filter(device => !initSelected.includes(device.id));
    return [...topDevices, ...bottomDevices];
  }, [initAssignedDevices, machineList]);

  const pagedData = useMemo(() => {
    const start = (page - 1) * 5;
    const end = start + 5;
    return sortedData.slice(start, end);
  }, [sortedData, page]);

  const onPageChange = (value: number): void => {
    setPage(value);
  };

  const handleToggleSelectDevice = useCallback(
    (machineId: string) => {
      if (assignedDevices.includes(machineId)) {
        handleAssignedDevices(assignedDevices.filter(id => id && id !== machineId));
      } else {
        handleAssignedDevices([...assignedDevices, machineId]);
      }
    },
    [assignedDevices, handleAssignedDevices]
  );

  const togglePage = (): void => {
    const pagedIds = machineList.map(machine => machine?.id || '');
    const isSelected = pagedIds.every(id => assignedDevices.includes(id));
    if (isSelected) {
      handleAssignedDevices(assignedDevices.filter(id => id && !pagedIds.includes(id)));
    } else {
      handleAssignedDevices([...assignedDevices, ...pagedIds]);
    }
  };

  const isAllDataInPageSelected = machineList.every(machine => assignedDevices.includes(machine?.id || ''));

  const tableColumns = getSelectedMachinesColumns({ t, assignedDevices, togglePage, isAllDataInPageSelected });

  return (
    <Card
      className="operator-details-drawer__card operator-details-drawer__selected-devices"
      title={t('notificationList.insertPanel.assignedDevices')}
    >
      <Table
        loading={isLoading}
        columns={tableColumns}
        dataSource={pagedData}
        className="operator-details-drawer__device-list__table"
        rowKey="id"
        onRow={({ id }): { onClick(): void } => ({
          onClick: (): void => id && handleToggleSelectDevice(id),
        })}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('userList.operatorForm.form.tableNoDevicesFound')}
            />
          ),
        }}
        pagination={
          machineList && machineList.length > 5
            ? {
                total: machineList.length,
                pageSize: 5,
                page,
                onPageChange,
              }
            : undefined
        }
        scroll={{ y: undefined }}
      />
      {!isLoading && assignedDevices.length === 0 && (
        <div className="operator-details-drawer__device-list__error">
          {t('operatorDetailsPanel.selectedDevices.table.devicesRequired')}
        </div>
      )}
    </Card>
  );
};
