import styled from 'styled-components';
import { Card } from 'antd';

export const StyledNotificationsOperator = styled(Card)`
  display: flex;
  svg {
    height: 16px;
    width: 16px;
  }
  cursor: pointer;

  .ant-card-body {
    display: flex;
    width: 100%;
    padding: 16px 24px;
    border: 1px solid ${(props): string => props.theme.colors.brightGrey};
  }

  .notifications-operator {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-left: 0;
  }

  .notifications-operator__name {
    ${(props): string => props.theme.fontStyles.redesign.base.normal};
  }
`;
