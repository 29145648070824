export interface NotificationCategoryType {
  name: string;
  label: string;
  checked?: boolean;
}

export const getGeneralNotificationCategories = (): NotificationCategoryType[] => [
  {
    name: 'notifyReminder',
    label: 'notificationList.notificationSubscription.reminder',
  },
  {
    name: 'notifyLocationStatus',
    label: 'notificationList.notificationSubscription.locationStatus',
  },
];

export const getKiraNotificationCategories = (): NotificationCategoryType[] => [
  {
    name: 'notifyCleaningTaskInterrupted',
    label: 'notificationList.notificationSubscription.cleaningTaskInterrupted',
  },
  {
    name: 'notifyEmergencyStop',
    label: 'notificationList.notificationSubscription.emergencyStop',
  },
  {
    name: 'notifyErrorOccurred',
    label: 'notificationList.notificationSubscription.errorOccurred',
  },
  {
    name: 'notifyResources',
    label: 'notificationList.notificationSubscription.resources',
  },
  {
    name: 'notifyCleaningTaskFinished',
    label: 'notificationList.notificationSubscription.cleaningTaskFinished',
  },
];

export const getIotNotificationCategories = (): NotificationCategoryType[] => [
  {
    name: 'notifyBatteryDeepDischarge',
    label: 'notificationList.notificationSubscription.batteryDeepDischarge',
  },
  {
    name: 'notifyNoWorkStart',
    label: 'notificationList.notificationSubscription.noWorkStart',
  },
  {
    name: 'notifyPCM',
    label: 'notificationList.notificationSubscription.pcm',
  },
];
