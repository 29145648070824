import { useDispatch } from 'react-redux';
import { notificationsSubscriptionsActions } from '../state/notificationsSubscriptionsSlice';
import { StyledNotificationsOperator } from './NotificationsOperator.styles';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';

interface NotificationsOperatorProps {
  name: string;
  userId: string;
  language?: string;
  isOperator: boolean;
}
export const NotificationsOperator = (props: NotificationsOperatorProps): JSX.Element => {
  const dispatch = useDispatch();

  const handleOpenUpsertNotificationsOperator = (): void => {
    dispatch(notificationsSubscriptionsActions.openInsertOperatorDrawer());

    if (props.isOperator) {
      dispatch(notificationsSubscriptionsActions.getOperatorDetailRequest(props.userId));
    } else {
      dispatch(
        notificationsSubscriptionsActions.getNotificationUserRequest({
          name: props.name,
          language: props.language || 'en',
        })
      );
    }
  };

  return (
    <StyledNotificationsOperator>
      <div className="notifications-operator" onClick={handleOpenUpsertNotificationsOperator}>
        <span className="notifications-operator__name">{props.name}</span>
        <SvgIcon className="notifications-operator__arrow-icon" name="arrow"></SvgIcon>
      </div>
    </StyledNotificationsOperator>
  );
};
