import {
  ResponseNotificationsList,
  ResponseNotificationUser,
  Site,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { MachineAvailableToFilter } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { Optional } from 'lib/types/Optional';

export interface NotificationList {
  notifications: ResponseNotificationsList;
}

export interface NotificationListAvailableFilters {
  severities: string[];
  types: string[];
  categories: string[];
  sites: Site[];
  machines: Optional<MachineAvailableToFilter[]>;
}

export interface NotificationGetUserResponse {
  notificationGetUser: ResponseNotificationUser;
}

export enum NotificationType {
  LocationStatus = 'LOCATION_STATUS',
  Reminder = 'REMINDER',
  Undefined = 'UNDEFINED',
}

export enum NotificationSubscriptionsActionTypes {
  CREATE_OPERATOR = 'CREATE_OPERATOR',
  EDIT_OPERATOR = 'EDIT_OPERATOR',
  EDIT_NOTIFICATION_SUBSCRIPTION = 'EDIT_NOTIFICATION_SUBSCRIPTION',
}
