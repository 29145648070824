import styled from 'styled-components';
import { Drawer } from 'lib/components/Drawer/Drawer';

export const StyledUpsertNotificationsDrawer = styled(Drawer)`
  .ant-drawer-header {
    padding-top: 32px;
    border-bottom: none;
  }

  .insert-notifications-drawer-panel--header {
    .insert-notifications-drawer__icon {
      padding: 0;
    }
    .insert-notifications-drawer__name {
      ${(props): string => props.theme.fontStyles.redesign.xl.strong};
      text-transform: uppercase;
      margin-bottom: 0px;
      padding-left: 8px;
    }
  }

  .operator-form__checkbox-label.has-error {
    .ant-checkbox-inner {
      border-color: ${(props): string => props.theme.colors.semanticError};
    }
  }

  .operator-details-drawer__card {
    margin-bottom: 24px;
  }

  .ant-drawer-body {
    padding: 0px 24px 24px 24px;
  }

  .ant-card-head .ant-card-head-wrapper {
    height: 28px !important;
    margin: 0px 0px !important;
  }

  .ant-card-head {
    border-bottom: none;
    text-transform: unset;
    padding-top: 24px;
  }

  .ant-card-head-title {
    ${(props): string => props.theme.fontStyles.redesign.xl.strong};
    font-weight: 700;
  }

  .ant-card-body {
    padding-top: 20px;
  }

  .operator-details-drawer__information .ant-card-body {
    height: auto;

    .operator-details-drawer__input__select-phone-code {
      width: 30%;
      border-right: none;
      .operator-form__select-label {
        display: flex;
        align-items: center;
        & > :first-child {
          margin-bottom: 3px;
        }
        & > :last-child {
          margin: 0px;
        }
      }
    }
    .operator-details-drawer__input-phone-code {
      width: 70%;
    }

    .operator-form__note-options {
      ${(props): string => props.theme.fontStyles.p1.bold};
      color: ${(props): string => props.theme.colors.black};
      margin-top: 16px;
      text-transform: uppercase;
    }
  }

  .ant-form-item-label {
    ${(props): string => props.theme.fontStyles.redesign.sm.delete}
    text-transform: uppercase;
    font-weight: ${(props): number => props.theme.fontWeights.buttonM};
    padding-bottom: 0px;
  }

  .ant-form-item .ant-form-item-control-input {
    min-height: 32px;
  }

  .operator-details-drawer__input-group {
    margin-bottom: 16px;
  }

  .ant-card-bordered {
    border: 1px solid ${(props): string => props.theme.colors.brightGrey};
  }

  .operator-details-drawer__device-list__table {
    cursor: pointer;
    margin-bottom: 20px;

    .operation-selected-machines__name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .ant-table-body {
      overflow: auto !important;
    }

    .ant-table-footer {
      .pagination {
        .pagination__prev-button,
        .pagination__next-button {
          margin-top: -16px;
        }
        .ant-btn-icon {
          svg {
            width: 5.37px;
            height: 9.81px;
            margin-top: 0;
            path {
              stroke-width: 1;
            }
          }
        }
      }
    }
  }

  .operation-selected-devices__table__title {
    ${(props): string => props.theme.fontStyles.redesign.base.strong}
    font-weight: 500;
  }

  .ant-spin {
    width: 100%;
  }

  .ant-table-footer {
    height: 32px;
    padding: 16px 0px;

    .ant-btn {
      svg {
        width: 16px;
        height: 16px;
        margin-top: -32px;
      }
    }
  }

  .operator-details-drawer__selected-notifications .ant-card-body {
    height: auto;
  }

  .operator-details-drawer__selected-notifications--sub-title-wrapper {
    padding-bottom: 20px;
  }

  .operator-details-drawer__selected-notifications--sub-title {
    ${(props): string => props.theme.fontStyles.redesign.sm.strong}
    padding-top: ${(props): string => props.theme.spacing.md};
    padding-bottom: ${(props): string => props.theme.spacing.sm};
  }

  .operator-details-drawer__selected-notifications--recommended {
    ${(props): string => props.theme.fontStyles.redesign.sm.normal}
    color: ${(props): string => props.theme.colors.darkGrey};
  }

  .operator-details-drawer__selected-notifications--checkbox-row {
    .ant-form-item {
      margin-bottom: 0px;
      padding-bottom: 6px;
    }
  }

  .ant-drawer-footer {
    padding: 24px 25px 32px 25px;
  }

  .operator-details-drawer__delete-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .operator-details-drawer__changes-button-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .operator-details-drawer__secondary-button {
    padding: 8px 16px;
    span {
      ${(props): string => props.theme.fontStyles.redesign.lg.strong};
      font-weight: ${(props): number => props.theme.fontWeights.buttonM};
    }

    border: 2px solid ${(props): string => props.theme.colors.gray100};
  }

  .operation-selected-devices__check {
    height: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .operator-details-drawer__selected-devices {
    .operator-details-drawer__device-list__error {
      color: #ff4d4f;
    }
    .operation-selected-devices__check--error {
      .ant-checkbox-inner {
        border: 1px solid #ff4d4f;
      }
    }
  }

  .selected-machines-table__status-column {
    .status-pill {
      padding: 3px;
    }
  }
`;
