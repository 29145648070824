import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Col, Form, FormInstance, Row, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  getGeneralNotificationCategories,
  getIotNotificationCategories,
  getKiraNotificationCategories,
} from './notificationsCategories/NotificationCategories';
import { Card } from 'lib/components/Card/Card';
import { Checkbox } from 'lib/components/Checkbox/Checkbox';

interface NotificationsSelectProps {
  isFormLoading: boolean;
  formInstance: FormInstance<any>;
}

export const NotificationsSelect = ({ isFormLoading, formInstance }: NotificationsSelectProps): JSX.Element => {
  const { t } = useTranslation();
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [checkAll, setCheckAll] = useState(false);

  const allCheckboxes = [
    ...getGeneralNotificationCategories(),
    ...getKiraNotificationCategories(),
    ...getIotNotificationCategories(),
  ].map(item => item.name);

  useEffect(() => {
    setCheckAll(allCheckboxes.every(checkbox => formInstance.getFieldValue(checkbox)));
  }, [checkedList, allCheckboxes, formInstance]);

  const handleCheckAllChange = (e: CheckboxChangeEvent): void => {
    const checked = e.target.checked;
    setCheckAll(checked);

    setCheckedList(checked ? allCheckboxes : []);
    const newFieldsValue = allCheckboxes.reduce(
      (acc, name) => ({ ...acc, [name]: checked }),
      {} as Record<string, boolean>
    );
    formInstance.setFieldsValue(newFieldsValue);
  };

  const handleCheckboxChange = (name: string, checked: boolean): void => {
    const newCheckedList = checked ? [...checkedList, name] : checkedList.filter(item => item !== name);

    setCheckedList(newCheckedList);
    setCheckAll(newCheckedList.length === allCheckboxes.length);
  };

  return (
    <Card
      className="operator-details-drawer__card operator-details-drawer__selected-notifications"
      title={t('notificationList.insertPanel.notificationOptions')}
    >
      <Spin spinning={isFormLoading}>
        <div>
          <Row className="operator-details-drawer__selected-notifications--checkbox-row">
            <Checkbox value="notifyInformation" checked={checkAll} onChange={handleCheckAllChange}>
              {t('notificationList.notificationSubscription.selectAll')}
            </Checkbox>
          </Row>
          <Row>
            <span className="operator-details-drawer__selected-notifications--sub-title">
              {t('notificationList.notificationSubscription.general')}
            </span>
          </Row>
          <Row className="operator-details-drawer__selected-notifications--checkbox-row">
            {getGeneralNotificationCategories().map(item => {
              const { label, name } = item;
              return (
                <Col key={`${name}`} span={12}>
                  <Form.Item name={name} valuePropName="checked">
                    <Checkbox
                      className="operator-details-drawer__selected-notifications--checkbox"
                      checked={checkedList.includes(name)}
                      onChange={(e): void => handleCheckboxChange(name, e.target.checked)}
                    >
                      <div className="operator-details-drawer__selected-notifications--checkbox-label">{t(label)}</div>
                    </Checkbox>
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
          <Row>
            <span className="operator-details-drawer__selected-notifications--sub-title">
              {t('notificationList.notificationSubscription.kiraNotifications')}
            </span>
          </Row>
          <Row className="operator-details-drawer__selected-notifications--checkbox-row">
            {getKiraNotificationCategories().map(item => {
              const { label, name } = item;
              return (
                <Col key={`${name}`} span={12}>
                  <Form.Item name={name} valuePropName="checked">
                    <Checkbox
                      className="operator-details-drawer__selected-notifications--checkbox"
                      checked={checkedList.includes(name)}
                      onChange={(e): void => handleCheckboxChange(name, e.target.checked)}
                    >
                      <div className="operator-details-drawer__selected-notifications--checkbox-label">{t(label)}</div>
                    </Checkbox>
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
          <Row>
            <span className="operator-details-drawer__selected-notifications--sub-title">
              {t('notificationList.notificationSubscription.iotNotifications')}
            </span>
          </Row>
          <Row className="operator-details-drawer__selected-notifications--checkbox-row">
            {getIotNotificationCategories().map(item => {
              const { label, name } = item;
              return (
                <Col key={`${name}`} span={12}>
                  <Form.Item name={name} valuePropName="checked">
                    <Checkbox
                      className="operator-details-drawer__selected-notifications--checkbox"
                      checked={checkedList.includes(name)}
                      onChange={(e): void => handleCheckboxChange(name, e.target.checked)}
                    >
                      <div className="operator-details-drawer__selected-notifications--checkbox-label">{t(label)}</div>
                    </Checkbox>
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
        </div>
      </Spin>
    </Card>
  );
};
