import { createSlice } from '@reduxjs/toolkit';
import { countries } from '../UpsertNotificationsOperator/countryData/countryData';
import { NotificationGetUserResponse, NotificationSubscriptionsActionTypes } from '../../interfaces/Notification.types';
import { GetOperatorResponse, UpsertNotificationFormValues } from '../../interfaces/Operator.types';
import * as NotificationSubscriptionsTypes from './notificationSubscriptionsActions.types';
import { AssignedMachineType, Machine } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

export interface InsertOperatorDrawerState {
  isOpen: boolean;
  type: Optional<NotificationSubscriptionsActionTypes>;
  isSubmitFormLoading: boolean;
  isDeleteOperatorLoading: boolean;
  formData: UpsertNotificationFormValues;
  machines: {
    data: Machine[];
    isLoading: boolean;
  };
}

export interface NotificationSubscriptionsDrawerState {
  isOpen: boolean;
}

export interface NotificationSubscriptionsState {
  insertOperatorDrawer: InsertOperatorDrawerState;
  notificationDrawer: NotificationSubscriptionsDrawerState;
}

export const initialState: NotificationSubscriptionsState = {
  insertOperatorDrawer: {
    isOpen: false,
    type: null,
    isSubmitFormLoading: false,
    isDeleteOperatorLoading: false,
    formData: {
      assignedMachines: [],
      countryCode: 'DE',
      email: '',
      emailSubscription: false,
      language: 'en',
      name: '',
      notifyAutonomousTaskStopped: false,
      notifyBatteryDeepDischarge: false,
      notifyCleaningTaskFinished: false,
      notifyCleaningTaskInterrupted: false,
      notifyEmergencyStop: false,
      notifyErrorOccurred: false,
      notifyInformation: false,
      notifyLocationStatus: false,
      notifyNoWorkStart: false,
      notifyOther: false,
      notifyPCM: false,
      notifyReminder: false,
      notifyResources: false,
      notifyServiceNeeded: false,
      numberOfAssignedMachines: 0,
      phoneNumber: '',
      smsSubscription: false,
      timezone: 'Europe/Berlin',
      isFormLoading: false,
    },
    machines: {
      isLoading: false,
      data: [],
    },
  },
  notificationDrawer: {
    isOpen: false,
  },
};

const notificationsSubscriptionsSlice = createSlice({
  name: 'notificationsSubscriptions',
  initialState,
  reducers: {
    openInsertOperatorDrawer: (state, _action: NotificationSubscriptionsTypes.InsertOperatorOpenAction) => {
      state.insertOperatorDrawer.isOpen = true;
    },
    closeInsertOperatorDrawer: (state, _action: NotificationSubscriptionsTypes.InsertOperatorCloseAction) => {
      state.insertOperatorDrawer.isOpen = false;
    },
    listMachineRequest: (state, _actions: NotificationSubscriptionsTypes.ListMachineRequestAction) => {
      state.insertOperatorDrawer.machines.isLoading = true;
    },
    listMachineSuccess: (state, action: NotificationSubscriptionsTypes.ListMachineSuccessAction) => {
      state.insertOperatorDrawer.machines.data = action.payload?.machines.data || [];
      state.insertOperatorDrawer.machines.isLoading = false;
    },
    listMachineError: (state, _action: NotificationSubscriptionsTypes.ListMachineErrorAction) => {
      state.insertOperatorDrawer.machines.isLoading = false;
    },
    getNotificationUserRequest: (state, action: NotificationSubscriptionsTypes.GetNotificationDetailRequestAction) => {
      const { name, language } = action.payload;
      state.insertOperatorDrawer.formData.isFormLoading = true;
      state.insertOperatorDrawer.formData.name = name;
      state.insertOperatorDrawer.formData.language = language;

      state.insertOperatorDrawer.type = NotificationSubscriptionsActionTypes.EDIT_NOTIFICATION_SUBSCRIPTION;
    },
    getNotificationUserSuccess: (state, action: NotificationSubscriptionsTypes.GetNotificationDetailSuccessAction) => {
      const { notificationGetUser } = action.payload as NotificationGetUserResponse;

      const phoneCode = countries.find(
        country => country.countryCode === notificationGetUser.data?.countryCode
      )?.phoneCode;

      state.insertOperatorDrawer.formData = {
        ...state.insertOperatorDrawer.formData,
        assignedMachines: (notificationGetUser.data?.assignedMachines as AssignedMachineType[]) || [],
        countryCode: notificationGetUser.data?.countryCode || 'DE',
        email: notificationGetUser.data?.email || '',
        emailSubscription: notificationGetUser.data?.emailSubscription || false,
        id: notificationGetUser.data?.id || '',
        notifyAutonomousTaskStopped: notificationGetUser.data?.notifyAutonomousTaskStopped || false,
        notifyBatteryDeepDischarge: notificationGetUser.data?.notifyBatteryDeepDischarge || false,
        notifyCleaningTaskFinished: notificationGetUser.data?.notifyCleaningTaskFinished || false,
        notifyCleaningTaskInterrupted: notificationGetUser.data?.notifyCleaningTaskInterrupted || false,
        notifyEmergencyStop: notificationGetUser.data?.notifyEmergencyStop || false,
        notifyErrorOccurred: notificationGetUser.data?.notifyErrorOccurred || false,
        notifyInformation: notificationGetUser.data?.notifyInformation || false,
        notifyLocationStatus: notificationGetUser.data?.notifyLocationStatus || false,
        notifyNoWorkStart: notificationGetUser.data?.notifyNoWorkStart || false,
        notifyOther: notificationGetUser.data?.notifyOther || false,
        notifyPCM: notificationGetUser.data?.notifyPCM || false,
        notifyReminder: notificationGetUser.data?.notifyReminder || false,
        notifyResources: notificationGetUser.data?.notifyResources || false,
        notifyServiceNeeded: notificationGetUser.data?.notifyServiceNeeded || false,
        phoneNumber: notificationGetUser.data?.phoneNumber?.replace(phoneCode || '', '') ?? '',
        smsSubscription: notificationGetUser.data?.smsSubscription || false,
        isFormLoading: false,
      };

      return state;
    },
    getNotificationUserError: (state, _action: NotificationSubscriptionsTypes.GetNotificationDetailErrorAction) => {
      state.insertOperatorDrawer.formData.isFormLoading = false;
    },
    getOperatorDetailRequest: (state, _action: NotificationSubscriptionsTypes.GetOperatorDetailRequestAction) => {
      state.insertOperatorDrawer.formData.isFormLoading = true;
      state.insertOperatorDrawer.type = NotificationSubscriptionsActionTypes.EDIT_OPERATOR;
    },
    getOperatorDetailSuccess: (state, action: NotificationSubscriptionsTypes.GetOperatorDetailSuccessAction) => {
      const { notificationGetOperator } = action.payload as GetOperatorResponse;

      const phoneCode = countries.find(
        country => country.countryCode === notificationGetOperator.data?.countryCode
      )?.phoneCode;
      state.insertOperatorDrawer.formData = {
        ...state.insertOperatorDrawer.formData,
        assignedMachines: (notificationGetOperator.data?.assignedMachines as AssignedMachineType[]) || [],
        countryCode: notificationGetOperator.data?.countryCode || 'DE',
        email: notificationGetOperator.data?.email || '',
        emailSubscription: notificationGetOperator.data?.emailSubscription || false,
        id: notificationGetOperator.data?.id || '',
        language: notificationGetOperator.data?.language || 'en',
        name: notificationGetOperator.data?.name || '',
        notifyAutonomousTaskStopped: notificationGetOperator.data?.notifyAutonomousTaskStopped || false,
        notifyBatteryDeepDischarge: notificationGetOperator.data?.notifyBatteryDeepDischarge || false,
        notifyCleaningTaskFinished: notificationGetOperator.data?.notifyCleaningTaskFinished || false,
        notifyCleaningTaskInterrupted: notificationGetOperator.data?.notifyCleaningTaskInterrupted || false,
        notifyEmergencyStop: notificationGetOperator.data?.notifyEmergencyStop || false,
        notifyErrorOccurred: notificationGetOperator.data?.notifyErrorOccurred || false,
        notifyInformation: notificationGetOperator.data?.notifyInformation || false,
        notifyLocationStatus: notificationGetOperator.data?.notifyLocationStatus || false,
        notifyNoWorkStart: notificationGetOperator.data?.notifyNoWorkStart || false,
        notifyOther: notificationGetOperator.data?.notifyOther || false,
        notifyPCM: notificationGetOperator.data?.notifyPCM || false,
        notifyReminder: notificationGetOperator.data?.notifyReminder || false,
        notifyResources: notificationGetOperator.data?.notifyResources || false,
        notifyServiceNeeded: notificationGetOperator.data?.notifyServiceNeeded || false,
        phoneNumber: notificationGetOperator.data?.phoneNumber?.replace(phoneCode || '', '') || '',
        smsSubscription: notificationGetOperator.data?.smsSubscription || false,
        isFormLoading: false,
      };
    },
    getOperatorDetailError: (state, _action: NotificationSubscriptionsTypes.GetOperatorDetailErrorAction) => {
      state.insertOperatorDrawer.formData.isFormLoading = false;
    },
    changetypeInsertOperatorDrawer: (state, action: NotificationSubscriptionsTypes.ActionTypesRequest) => {
      state.insertOperatorDrawer.type = action.payload;
    },
    upsertOperatorRequest: (state, _action: NotificationSubscriptionsTypes.UpsertOperatorRequestAction) => {
      state.insertOperatorDrawer.isSubmitFormLoading = true;
    },
    upsertOperatorSuccess: (state, _action: NotificationSubscriptionsTypes.UpsertOperatorSuccessAction) => {
      state.insertOperatorDrawer.isSubmitFormLoading = false;
    },
    upsertOperatorError: (state, _action: NotificationSubscriptionsTypes.UpsertOperatorErrorAction) => {
      state.insertOperatorDrawer.isSubmitFormLoading = false;
    },
    updateNotificationUserRequest: (
      state,
      _action: NotificationSubscriptionsTypes.UpdateNotificationUserRequestAction
    ) => {
      state.insertOperatorDrawer.isSubmitFormLoading = true;
    },
    updateNotificationUserSuccess: (
      state,
      _action: NotificationSubscriptionsTypes.UpdateNotificationUserSuccessAction
    ) => {
      state.insertOperatorDrawer.isSubmitFormLoading = false;
    },
    updateNotificationUserError: (state, _action: NotificationSubscriptionsTypes.UpdateNotificationUserErrorAction) => {
      state.insertOperatorDrawer.isSubmitFormLoading = false;
    },
    deleteOperatorRequest: (state, _action: NotificationSubscriptionsTypes.DeleteOperatorRequestAction) => {
      state.insertOperatorDrawer.isDeleteOperatorLoading = true;
    },
    deleteOperatorSuccess: (state, _action: NotificationSubscriptionsTypes.DeleteOperatorSuccessAction) => {
      state.insertOperatorDrawer.isDeleteOperatorLoading = false;
    },
    deleteOperatorError: (state, _action: NotificationSubscriptionsTypes.DeleteOperatorErrorAction) => {
      state.insertOperatorDrawer.isDeleteOperatorLoading = false;
    },

    saveFormData: (state, action: NotificationSubscriptionsTypes.SaveFormData) => {
      state.insertOperatorDrawer.formData = action.payload;
    },

    resetNotificationSubscriptions: () => initialState,
  },
});

export const notificationsSubscriptionsActions = {
  ...notificationsSubscriptionsSlice.actions,
};
export const notificationsSubscriptionsReducer = notificationsSubscriptionsSlice.reducer;
