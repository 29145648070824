import React, { ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table/InternalTable';
import { Machine } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { MachineStatusIndicator } from 'app/modules/machine-inventory/components/MachineStatusIndicator/MachineStatusIndicator';
import { Optional } from 'lib/types/Optional';
import { Checkbox } from 'lib/components/Checkbox/Checkbox';

interface SelectedMachinesColumnsProps {
  assignedDevices: Optional<string>[];
  t: TFunction;
  togglePage: () => void;
  isAllDataInPageSelected: boolean;
}

export const getSelectedMachinesColumns = ({
  assignedDevices,
  t,
  togglePage,
  isAllDataInPageSelected,
}: SelectedMachinesColumnsProps): ColumnsType<Machine> => [
  {
    title: (
      <div className="operation-selected-devices__check">
        <Checkbox
          className={`
      ${assignedDevices.length === 0 ? 'operation-selected-devices__check--error' : ''}
      `}
          checked={isAllDataInPageSelected}
          onChange={togglePage}
        />
      </div>
    ),
    dataIndex: 'id',
    width: 30,
    key: 'id',
    render: (id): ReactNode => (
      <div className="operation-selected-devices__check">
        <Checkbox
          className={`
          ${assignedDevices.length === 0 ? 'operation-selected-devices__check--error' : ''}
          `}
          checked={assignedDevices.includes(id)}
        />
      </div>
    ),
  },
  {
    title: t('operatorDetailsPanel.selectedDevices.table.columns.name'),
    dataIndex: 'name',
    width: 170,
    key: 'name',
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: t('operatorDetailsPanel.selectedDevices.table.columns.serialNo'),
    dataIndex: 'serialNumber',
    key: 'serialNumber',
    width: 90,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: t('operatorDetailsPanel.selectedDevices.table.columns.materialNo'),
    dataIndex: 'materialNumber',
    key: 'materialNumber',
    width: 107,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: t('operatorDetailsPanel.selectedDevices.table.columns.status'),
    dataIndex: 'lastActivityAt',
    key: 'lastActivityAt',
    render: (lastActivityAt): React.JSX.Element => (
      <MachineStatusIndicator lastActivityAt={lastActivityAt} className="selected-machines-table__status-column" />
    ),
    width: 67,
    align: 'center',
    ellipsis: {
      showTitle: true,
    },
  },
];
